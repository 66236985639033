// import router from "./../router";
import Utils from "../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    title: {
      text: "",
      params: ""
    },
    message: {
      text: "",
      params: null
    },
    cancelBtn: {
      label: "",
      callback: null
    },
    confirmBtn: {
      label: "",
      callback: null
    },
    type: null // OPTIONS: PROCEED, DELETE, null
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getTitleText: state => state.title.text,
  getTitleParams: state => state.title.params,
  getMessageText: state => state.message.text,
  getMessageParams: state => state.message.params,
  getCancelBtnLabel: state => state.cancelBtn.label,
  getCancelBtnClb: state => state.cancelBtn.callback,
  getConfirmBtnLabel: state => state.confirmBtn.label,
  getConfirmBtnCallback: state => state.confirmBtn.callback,
  getType: state => state.type
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_CONFIRM(
    state,
    {
      titleText = "dialog.confirm.defaultTitle",
      titleParams = null,
      messageText = "dialog.confirm.defaultMessage",
      messageParams = null,
      cancelBtnLabel = "button.cancel",
      cancelBtnCallback = null,
      confirmBtnLabel = "button.proceed",
      confirmBtnCallback = null,
      type = null
    }
  ) {
    state.active = true;
    state.title.text = titleText;
    state.title.params = titleParams;
    state.message.text = messageText;
    state.message.params = messageParams;
    state.cancelBtn.label = cancelBtnLabel;
    state.cancelBtn.callback = cancelBtnCallback;
    state.confirmBtn.label = confirmBtnLabel;
    state.confirmBtn.callback = confirmBtnCallback;
    state.type = type;
  }
};

const actions = {
  cancelAction({ getters, commit }) {
    const cancelClb = getters.getCancelBtnClb;
    if (Utils.isFunction(cancelClb)) cancelClb();

    commit("RESET");
  },
  confirmAction({ getters, commit }) {
    const confirmClb = getters.getConfirmBtnCallback;
    if (Utils.isFunction(confirmClb)) confirmClb();

    commit("RESET");
  }
};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
