import swaggerClient from "../plugins/swagger-client";

export default class BillingService {
  retrieveInvoices(clientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.invoices.retrieveInvoices(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
