import swaggerClient from "./../plugins/swagger-client";

export default class FolderService {

  retrieveFoldersByClientId(clientId) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.retrieveFolders(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveFolderByClientId(clientId, folderId) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.retrieveFolder(
          { clientId, folderId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  createFolderByClientId(clientId, payload) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.createFolder(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteFolderByClientId(clientId, folderId) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.deleteFolder(
          { clientId, folderId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateFolderByClientId(clientId, folderId, payload) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.updateFolder(
          { clientId, folderId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  addProjectToFolderByClientId(clientId, folderId, projectId) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.addProjectToFolder(
          { clientId, folderId },
          swaggerClient.buildRequestBody({projectId:projectId}),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  removeProjectFromFolder(clientId, folderId, projectId) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.folders.removeProjectFromFolder(
          { clientId, folderId, projectId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  
}
