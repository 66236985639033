import router from "../router";
import Utils from "../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    activeStep: null,
    lastActiveStep: Utils.WIZARD_STEPS.AUDIENCE,
    completedStep: Utils.WIZARD_STEPS.CHECKOUT,
    projectPackageList: {
      [Utils.PROJECT_PACKAGE.DISCOVER]: {
        name: "Discover",
        totalVideo: 1,
        maxMinute: 1,
        totalRespondents: 150,
        defaultPrice: 1000
      },
      [Utils.PROJECT_PACKAGE.TEST]: {
        name: "A/B Test",
        totalVideo: 2,
        maxMinute: 2,
        totalRespondents: 150,
        defaultPrice: 1900
      },
      [Utils.PROJECT_PACKAGE.ABTEST_SEQUENTIAL]: {
        name: "A/B Test",
        totalVideo: 2,
        maxMinute: 2,
        totalRespondents: 150,
        defaultPrice: 1900
      },
      [Utils.PROJECT_PACKAGE.CUSTOM]: {
        name: "Custom",
        totalVideo: "-",
        maxMinute: 2,
        totalRespondents: 150,
        defaultPrice: 0
      }
    },
    activeProjectPackage: null,
    activeProjectName: "",
    activePanelProvider:Utils.PANEL_PROVIDERS.DEFAULT ,  // default == dynata, should eventually change
    steps: {
      [Utils.USER_TYPE.REGULAR]: {
        [Utils.WIZARD_STEPS.AUDIENCE]: {
          navName: "Audience",
          navIcon: "users",
          isValid: rootGetters => {
            const country = rootGetters["audience/getSelectedCountry"];
            const category = rootGetters["audience/getBrandCategory"];
            return country !== "" && category !== "";
          },
          routeName: "Audience",
          prevRouteName: null,
          nextRouteName: "Stimulus",
          nextStep: Utils.WIZARD_STEPS.STIMULUS
        },
        [Utils.WIZARD_STEPS.STIMULUS]: {
          navName: "Upload",
          navIcon: "upload",
          isValid: (rootGetters, getters) => {
            const packageTotalVideos =
              getters.getActiveProjectPackageInfo.totalVideo;
            return (
              rootGetters["stimulus/getStimulusList"].length ===
              packageTotalVideos
            );
          },
          routeName: "Stimulus",
          prevRouteName: "Audience",
          nextRouteName: "Review",
          nextStep: Utils.WIZARD_STEPS.REVIEW
        },
        [Utils.WIZARD_STEPS.REVIEW]: {
          navName: "Check",
          navIcon: "checked",
          isValid: rootGetters => {
            return true;
          },
          routeName: "Review",
          prevRouteName: "Stimulus",
          nextRouteName: "Checkout",
          nextStep: Utils.WIZARD_STEPS.CHECKOUT
        },
        [Utils.WIZARD_STEPS.CHECKOUT]: {
          navName: "Pay",
          navIcon: "shopping-cart",
          isValid: rootGetters => {
            return false;
          },
          routeName: "Checkout",
          prevRouteName: "Review",
          nextRouteName: null
        }
      },
      [Utils.USER_TYPE.CUSTOMER]: {
        [Utils.WIZARD_STEPS.AUDIENCE]: {
          navName: "Audience",
          navIcon: "users",
          isValid: rootGetters => {
            const country = rootGetters["audience/getSelectedCountry"];
            const category = rootGetters["audience/getBrandCategory"];
            const genderList = rootGetters["audience/getSelectedGenderList"];
            const ageRangeList =
              rootGetters["audience/getSelectedAgeRangeList"];
            const regions = rootGetters["audience/getSelectedRegionList"];
            return (
              country !== "" &&
              category !== "" &&
              genderList.length > 0 &&
              ageRangeList.length > 0 &&
              regions.length > 0
            );
          },
          routeName: "Audience",
          prevRouteName: null,
          nextRouteName: "Stimulus",
          nextStep: Utils.WIZARD_STEPS.STIMULUS
        },
        [Utils.WIZARD_STEPS.STIMULUS]: {
          navName: "Upload",
          navIcon: "upload",
          isValid: rootGetters => {
            return rootGetters["stimulus/getStimulusList"].length > 0;
          },
          routeName: "Stimulus",
          prevRouteName: "Audience",
          nextRouteName: "Questions",
          nextStep: Utils.WIZARD_STEPS.QUESTIONS
        },
        [Utils.WIZARD_STEPS.QUESTIONS]: {
          navName: "Questions",
          navIcon: "pencil-alt-2",
          isValid: rootGetters => {
            return true;
          },
          routeName: "Questions",
          prevRouteName: "Stimulus",
          nextRouteName: "Review",
          nextStep: Utils.WIZARD_STEPS.REVIEW
        },
        [Utils.WIZARD_STEPS.REVIEW]: {
          navName: "Check",
          navIcon: "checked",
          isValid: rootGetters => {
            return false;
          },
          routeName: "Review",
          prevRouteName: "Questions",
          nextRouteName: null
        }
      }
    },
    projectStatus: {
      [Utils.USER_TYPE.REGULAR]: {
        isReady: getters => {
          return false;
        }
      },
      [Utils.USER_TYPE.CUSTOMER]: {
        isReady: getters => {
          return (
            getters.isProjectValid &&
            getters.getLastActiveStep === Utils.WIZARD_STEPS.REVIEW
          );
        }
      }
    }
  };
};

const state = getDefaultState();

const getters = {
  getActiveStep: state => state.activeStep,
  getLastActiveStep: state => state.lastActiveStep,
  getSteps: state => state.steps,
  getProjectPackageList: state => state.projectPackageList,
  getActiveProjectPackage: state => state.activeProjectPackage,
  getActiveProjectPackageInfo: state =>
    state.projectPackageList[state.activeProjectPackage],
  getActiveProjectName: state => state.activeProjectName,
  getActivePanelProvider : state =>state.activePanelProvider,
  isValid: (state, getters, rootState, rootGetters) =>
    state.activeStep &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep] &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep].isValid(
      rootGetters,
      getters
    ),
  getCurrentRouteName: (state, getters, rootState, rootGetters) =>
    state.activeStep &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep] &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep].routeName,
  getPreviousRouteName: (state, getters, rootState, rootGetters) =>
    state.activeStep &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep] &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep]
      .prevRouteName,
  getNextRouteName: (state, getters, rootState, rootGetters) =>
    state.activeStep &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep] &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep]
      .nextRouteName,
  getNextStep: (state, getters, rootState, rootGetters) =>
    state.activeStep &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep] &&
    state.steps[rootGetters["auth/getUserType"]][state.activeStep].nextStep,
  getNavSteps: (state, getters, rootState, rootGetters) => {
    const activeStep = state.activeStep;
    const userType = rootGetters["auth/getUserType"];
    const activeStepList = state.steps[userType];
    const navSteps = [];
    const completedStep = state.completedStep;

    for (var key in activeStepList) {
      navSteps.push({
        routeName: activeStepList[key].routeName,
        navName: activeStepList[key].navName,
        navIcon: activeStepList[key].navIcon,
        active: activeStep === parseInt(key),
        visited: activeStep >= parseInt(key),
        completedStep: completedStep > parseInt(key)
      });
    }

    return navSteps;
  },
  isProjectReady: (state, getters, rootState, rootGetters) =>
    state.projectStatus[rootGetters["auth/getUserType"]].isReady(getters),
  isProjectValid: (state, getters, rootState, rootGetters) => {
    const userType = rootGetters["auth/getUserType"];
    switch (userType) {
      case Utils.USER_TYPE.REGULAR:
        return (
          state.steps[userType][Utils.WIZARD_STEPS.AUDIENCE].isValid(
            rootGetters
          ) &&
          state.steps[userType][Utils.WIZARD_STEPS.STIMULUS].isValid(
            rootGetters,
            getters
          )
        );
      case Utils.USER_TYPE.CUSTOMER:
        return (
          state.steps[userType][Utils.WIZARD_STEPS.AUDIENCE].isValid(
            rootGetters
          ) &&
          state.steps[userType][Utils.WIZARD_STEPS.STIMULUS].isValid(
            rootGetters
          )
        );
      default:
        return false;
    }
  }
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE_STEP(state, activeStep) {
    state.activeStep = activeStep;
  },
  SET_LAST_ACTIVE_STEP(state, lastActiveStep) {
    state.lastActiveStep = lastActiveStep;
  },
  SET_ACTIVE_PROJECT_PACKAGE(state, activeProjectPackage) {
    state.activeProjectPackage = activeProjectPackage;
  },
  SET_ACTIVE_PROJECT_NAME(state, activeProjectName) {
    state.activeProjectName = activeProjectName;
  },
  SET_ACTIVE_PANEL_PROVIDER(state,activePanelProvider){
    state.activePanelProvider = activePanelProvider
  }
};

const actions = {
  goToPreviousStep({ getters }) {
    const routeName = getters.getPreviousRouteName;
    router.push({ name: routeName });
  },
  goToNextStep({ getters }) {
    const routeName = getters.getNextRouteName;
    router.push({ name: routeName });
  }
};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
