// import swaggerClient from "../plugins/swagger-client";

export default class CategoryService {
  retrieveProjectCategories() {
    return Promise.resolve([])
    // return swaggerClient.swagger
    //   .then(client => {
    //     return client.apis.categories.retrieveProjectCategories(
    //       {},
    //       swaggerClient.buildRequestBody(),
    //       {}
    //     );
    //   })
    //   .then(response => {
    //     return response.body;
    //   })
    //   .catch(error => {
    //     return Promise.reject(error);
    //   });
  }

  retrieveVideoCategories() {
    return Promise.resolve([])
    // return swaggerClient.swagger
    //   .then(client => {
    //     return client.apis.categories.retrieveVideoCategories(
    //       {},
    //       swaggerClient.buildRequestBody(),
    //       {}
    //     );
    //   })
    //   .then(response => {
    //     return response.body;
    //   })
    //   .catch(error => {
    //     return Promise.reject(error);
    //   });
  }
}
