/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
/* eslint-disable camelcase */
import services from "../services";
import router from "../router/index";
import jwt_decode from "jwt-decode";

const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
  return {
    isLoggedIn: false,
    sessionToken: null,
    sessionTokenPayload: {},
    clientId: null,
    isMultiUser: false,
    userType: null,
    userSubType: null,
    userBranchId: null,
    firstLogin: false,
    userInfo: {},
    registerUserModel: {
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      companyName: "",
      phone: "",
      jobTitle: "",
      agreeToTerms: false,
      newsletterRegistration: false,
      type: "predict",
      clientType: "regular",
    },
    userAccount: {},
  };
};

const state = getDefaultState();

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  getUserId: (state) => state.sessionTokenPayload.userId,
  getClientId: (state) => state.clientId,
  getSessionToken: (state) => state.sessionToken,
  getUserType: (state) => state.userType,
  getUserSubType: (state) => state.userSubType,
  getUserBranchId: (state) => state.userBranchId,
  isMultiUser: (state) => state.isMultiUser,
  hasProjects: () => false,
  isFirstLogin: (state) => state.firstLogin,
  getRegisterFirstName: (state) => state.registerUserModel.firstName,
  getRegisterLastName: (state) => state.registerUserModel.lastName,
  getRegisterEmail: (state) => state.registerUserModel.email,
  getRegisterCountry: (state) => state.registerUserModel.country,
  getRegisterCompanyName: (state) => state.registerUserModel.companyName,
  getRegisterPhone: (state) => state.registerUserModel.phone,
  getRegisterJobTitle: (state) => state.registerUserModel.jobTitle,
  isRegisterAgreeToTerms: (state) => state.registerUserModel.agreeToTerms,
  isRegisterNewsletter: (state) =>
    state.registerUserModel.newsletterRegistration,
  getRegisterUserInfo: (state) => state.registerUserModel,
  getUserAccount: (state) => state.userAccount,
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  RESET_REGISTER_USER_MODEL(state) {
    const s = getDefaultState();
    state.registerUserModel = s.registerUserModel;
  },
  SET_IS_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  SET_USER_TYPE(state, userType) {
    state.userType = userType;
  },
  SET_USER_SUBTYPE(state, userSubType) {
    state.userSubType = userSubType;
  },
  SET_USER_BRANCH_ID(state, userBranchId) {
    state.userBranchId = userBranchId;
  },
  SET_IS_MULTIUSER(state, isMultiUser) {
    state.isMultiUser = isMultiUser;
  },
  SET_FIRST_LOGIN(state, firstLogin) {
    state.firstLogin = firstLogin;
  },
  SET_SESSION_TOKEN(state, sessionToken) {
    state.sessionToken = "Bearer " + sessionToken;
  },
  SET_SESSION_TOKEN_PAYLOAD(state, sessionTokenPayload) {
    state.sessionTokenPayload = sessionTokenPayload;
  },
  SET_CLIENT_ID(state, clientId) {
    state.clientId = clientId;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_FIRST_NAME(state, firstName) {
    state.registerUserModel.firstName = firstName;
  },
  SET_LAST_NAME(state, lastName) {
    state.registerUserModel.lastName = lastName;
  },
  SET_EMAIL(state, email) {
    state.registerUserModel.email = email;
  },
  SET_COUNTRY(state, country) {
    state.registerUserModel.country = country;
  },
  SET_COMPANY_NAME(state, companyName) {
    state.registerUserModel.companyName = companyName;
  },
  SET_PHONE(state, phone) {
    state.registerUserModel.phone = phone;
  },
  SET_JOB_TITLE(state, jobTitle) {
    state.registerUserModel.jobTitle = jobTitle;
  },
  SET_AGREE_TO_TERMS(state, agreeToTerms) {
    state.registerUserModel.agreeToTerms = agreeToTerms;
  },
  SET_NEWSLETTER(state, newsletterRegistration) {
    state.registerUserModel.newsletterRegistration = newsletterRegistration;
  },
  SET_USER_ACCOUNT(state, userAccount) {
    state.userAccount = userAccount;
  },
};

const actions = {
  login({ commit }, { email, password }) {
    const userInfo = {
      email,
      password,
      domain: "client",
    };
    return services.UserService.loginUser(userInfo)
      .then((response) => {
        // commit("SET_USER_INFO", userInfo);
        const decodedToken = jwt_decode(response.token);
        commit("SET_CLIENT_ID", response.accountId);
        commit("SET_SESSION_TOKEN", response.token);
        commit("SET_SESSION_TOKEN_PAYLOAD", decodedToken);
        commit("SET_USER_TYPE", response.clientType);
        commit("SET_USER_SUBTYPE", decodedToken.subType || "admin");
        commit("SET_FIRST_LOGIN", response.firstLogin || false);
        commit("SET_USER_BRANCH_ID", decodedToken.branchId || "");
      })
      .catch((error) => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {
            titleText: "dialog.error.loginTitle",
            messageText: "dialog.error.loginMessage",
          },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  createUserAccount({ commit, getters }) {
    const userInfo = getters.getRegisterUserInfo;

    return services.UserService.createUserAccount(userInfo)
      .then(() => {})
      .catch((error) => {
        console.log(error);
        const messageText =
          error.response.body.errorDetails === "Email is not available"
            ? "emailNotAvailable"
            : null;
        commit(
          "error/SET_ERROR",
          { messageText },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  retrieveClient({ commit, getters }) {
    const clientId = getters.getClientId;
    return services.UserService.retrieveClient(clientId)
      .then((response) => {
        // HACK -> commit branches array to the multiuser service for separation of concepts
        commit("SET_USER_ACCOUNT", response);
        commit("SET_IS_MULTIUSER", response.multiUser);
        commit("multiuser/SET_BRANCHES", response.branches || [], {
          root: true,
        });
      })
      .catch((error) => {
        commit("SET_USER_ACCOUNT", {});
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  updateClient({ commit, getters }) {
    const clientId = getters.getClientId;
    const userAccount = getters.getUserAccount;

    /* TMP SOLUTION FOR EMAIL */
    const registrationEmail = userAccount.registration;
    delete userAccount.registration;

    return services.UserService.updateClient(clientId, userAccount)
      .then((response) => {
        response.registration = registrationEmail;
        commit("SET_USER_ACCOUNT", response);
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  changeUserPassword({ commit, getters }, passwordInfo) {
    const clientId = getters.getClientId;
    return services.UserService.changeUserPassword(clientId, passwordInfo)
      .then(() => {
        commit(
          "success/SET_SUCCESS_INFO",
          {
            titleText: "dialog.success.passwordChangeTitle",
            messageText: "dialog.success.passwordChangeMessage",
          },
          { root: true }
        );
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  confirmUser({ commit }, { password, token }) {
    return services.UserService.confirmUser({ password, token })
      .then(() => {})
      .catch((error) => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
  recoverUserPassword({ commit }, { email }) {
    return services.UserService.recoverUserPassword({
      email,
      type: "predict",
    })
      .then(() => {})
      .catch((error) => {
        console.log(error);
        commit(
          "error/SET_ERROR",
          {},
          {
            root: true,
          }
        );
        return Promise.reject();
      });
  },
};

function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("SET_IS_LOGGED_IN", false);
    router.replace({ name: "Login" });
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions,
};
