import swaggerClient from "../plugins/swagger-client";

export default class UserService {
  loginUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.loginUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createUserAccount(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.createUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  deleteUser(userId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.deleteUser(
          { userId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  retrieveUsers({ limit, skip, clientId, subType } = {}) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.retrieveUsers(
          { limit, skip, clientId, subType },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateUser(userId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.updateUser(
          { userId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  
  retrieveClient(clientId) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.retrieveClient(
          { clientId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  updateClient(clientId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.clients.updateClient(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(response => {
        return response.body;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  createBranchByClientId(clientId, payload) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.clients.createBranch(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateBranchByClientIdAndBranchId(clientId, branchId, payload) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.clients.updateBranch(
          { clientId, branchId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteBranchByClientIdAndBranchId(clientId, branchId) {
    return swaggerClient.swagger
      .then((client) => {
        return client.apis.clients.deleteBranch(
          { clientId, branchId },
          swaggerClient.buildRequestBody(),
          {}
        );
      })
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }


  changeUserPassword(clientId, payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.changeUserPassword(
          { clientId },
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(() => {})
      .catch(error => {
        return Promise.reject(error);
      });
  }

  confirmUser(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.confirmUser(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(() => {})
      .catch(error => {
        return Promise.reject(error);
      });
  }

  recoverUserPassword(payload) {
    return swaggerClient.swagger
      .then(client => {
        return client.apis.users.recoverUserPassword(
          {},
          swaggerClient.buildRequestBody(payload),
          {}
        );
      })
      .then(() => {})
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
