// import router from "./../router";
import Utils from "../Utils";
const strict = true;

const getDefaultState = () => {
  return {
    active: false,
    title: {
      text: "",
      params: ""
    },
    message: {
      text: "",
      params: null
    },
    confirmBtn: {
      label: "",
      callback: null
    }
  };
};

const state = getDefaultState();

const getters = {
  isActive: state => state.active,
  getTitleText: state => state.title.text,
  getTitleParams: state => state.title.params,
  getMessageText: state => state.message.text,
  getMessageParams: state => state.message.params,
  getConfirmBtnLabel: state => state.confirmBtn.label,
  getConfirmBtnCallback: state => state.confirmBtn.callback
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ACTIVE(state, active) {
    state.active = active;
  },
  SET_SUCCESS_INFO(
    state,
    {
      titleText = "dialog.success.defaultTitle",
      titleParams = null,
      messageText = "dialog.success.defaultMessage",
      messageParams = null,
      confirmBtnLabel = "button.ok",
      confirmBtnCallback = null
    }
  ) {
    state.active = true;
    state.title.text = titleText;
    state.title.params = titleParams;
    state.message.text = messageText;
    state.message.params = messageParams;
    state.confirmBtn.label = confirmBtnLabel;
    state.confirmBtn.callback = confirmBtnCallback;
  }
};

const actions = {
  confirmAction({ getters, commit }) {
    const confirmClb = getters.getConfirmBtnCallback;
    if (Utils.isFunction(confirmClb)) confirmClb();

    commit("RESET");
  }
};

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
