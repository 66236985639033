/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "../services";
import router from "../router/index";

const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
  return {
    invoiceList: []
  };
};

const state = getDefaultState();

const getters = {
  getInvoiceList: state => state.invoiceList
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_INVOICE_LIST(state, invoiceList) {
    state.invoiceList = invoiceList;
  }
};

const actions = {
  retrieveInvoices({ commit, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    return services.BillingService.retrieveInvoices(clientId)
      .then(response => {
        commit("SET_INVOICE_LIST", response);
      })
      .catch(error => {
        commit("SET_INVOICE_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("SET_IS_LOGGED_IN", false);
    router.replace({ name: "Login" });
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
