import services from "../services";
import router from "../router/index";
import Utils from "../Utils";

const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
  return {
    folderList: [],
    activeFolder: {},
    projectList: [],
  };
};

const state = getDefaultState();

const getters = {
  getFolderList: (state) => state.folderList,
  getActiveFolder: (state) => state.activeFolder,
  getProjectList: (state) => groupProjects(state.activeFolder.projects)
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_FOLDERS_LIST(state, folderList) {
    state.folderList = folderList;
  },
  SET_ACTIVE_FOLDER(state, folder) {
    state.activeFolder = folder;
  },
  SET_PROJECT_LIST(state, projectList) {
    state.projectList = projectList;
  },
};

const actions = {
  retrieveFoldersByClientId({ commit, rootGetters }) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.retrieveFoldersByClientId(clientId)
      .then((response) => {
        return response;
      })
      .then((folders) => {
        commit("SET_FOLDERS_LIST", folders);
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_FOLDERS_LIST", []);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  retrieveFolderByClientId({ commit, rootGetters }, folderId) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.retrieveFolderByClientId(clientId, folderId)
      .then((response) => {
        commit("SET_ACTIVE_FOLDER", response);
      })
      .catch((error) => {
        if (error instanceof DOMException && error.name === 'QuotaExceededError') {
          console.error('Storage quota exceeded. Unable to save state.');
          return;
        }
        console.error("Error while retrieving folder data: ",error);
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  createFolderByClientId({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.createFolderByClientId(clientId, payload)
      .then((response) => {
        commit("SET_ACTIVE_FOLDER", {});
        // router.push({
        //   name:"Folder",
        //   params:{id:response._id}
        // });
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(error);
      });
  },
  deleteFolderByClientId({ commit, rootGetters }, folderId) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.deleteFolderByClientId(clientId, folderId)
      .then((response) => {
        commit("SET_ACTIVE_FOLDER", {});
      })
      .catch((error) => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(error);
      });
  },
  updateFolderByClientId({ commit, rootGetters }, folder) {
    const clientId = rootGetters["auth/getClientId"];
    return services.FolderService.updateFolderByClientId(
      clientId,
      folder._id,
      folder
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  addProjectToFolder({ commit, rootGetters }, payload) {
    const clientId = rootGetters["auth/getClientId"];
    const folderId = payload.folderId
    const projectId = payload.projectId
    return services.FolderService.addProjectToFolderByClientId(
      clientId,
      folderId,
      projectId
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
  removeProjectFromFolder({ commit, rootGetters },payload) {
    const clientId = rootGetters["auth/getClientId"];
    const folderId= payload.folderId;
    const projectId = payload.projectId;
    return services.FolderService.removeProjectFromFolder(
      clientId,
      folderId,
      projectId
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const isAuthorizationError =
          error.status === AUTHORIZATION_ERROR_STATUS;
        checkRedirectionByStatus(error.status, commit);
        commit("SET_ACTIVE_FOLDER", {});
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true,
          }
        );
        return Promise.reject(isAuthorizationError);
      });
  },
};

// utils
function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("auth/SET_IS_LOGGED_IN", false, { root: true });
    router.replace({ name: "Login" });
  }
}

function groupProjects(projects) {
  const projectGroupedList = [
    {
      label: "pending",
      status: Utils.PROJECT_STATUS.PENDING,
      list: [],
    },
    {
      label: "active",
      status: Utils.PROJECT_STATUS.ACTIVE,
      list: [],
    },
    {
      label: "closed",
      status: Utils.PROJECT_STATUS.CLOSED,
      list: [],
    },
  ];

  let index;
  projects.forEach((project) => {
    switch (project.status) {
      case Utils.PROJECT_STATUS.PENDING:
        index = 0;
        break;
      case Utils.PROJECT_STATUS.ACTIVE:
        index = 1;
        break;
      case Utils.PROJECT_STATUS.CLOSED:
        index = 2;
        break;
      default:
        index = -1;
        break;
    }
    index > -1 && projectGroupedList[index].list.push(project);
  });

  return projectGroupedList;
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  actions,
  mutations,
};
