/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "../services";
import router from "../router/index";
import orderBy from "lodash/orderBy";

const AUTHORIZATION_ERROR_STATUS = 401;
const strict = true;

const getDefaultState = () => {
  return {
    stimulusList: [],
    categoryOptions: [],
    contentTypeOptions: [
      { text: "advertising", value: "advertisment" },
      { text: "brandedContent", value: "branded" },
      { text: "genericContnent", value: "generic" },
      { text: "other", value: "other" }
    ],
    developmentStageOptions: [
      { text: "concept", value: "concept" },
      { text: "animatic", value: "animatic" },
      { text: "firstCut", value: "draft" },
      { text: "finalEdit", value: "final" }
    ]
  };
};

const state = getDefaultState();

const getters = {
  getStimulusList: state => state.stimulusList,
  getCategoryOptions: state => state.categoryOptions,
  getContentTypeOptions: state => state.contentTypeOptions,
  getDevelopmentStageOptions: state => state.developmentStageOptions
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_STIMULUS_LIST(state, stimulusList) {
    state.stimulusList = stimulusList;
  },
  ADD_STIMULUS(state, stimulus) {
    state.stimulusList.push(stimulus);
  },
  UPDATE_STIMULUS(state, stimulus) {
    const stimulusIndex = state.stimulusList.findIndex(
      s => s._id === stimulus._id
    );
    state.stimulusList[stimulusIndex] = stimulus;
  },
  REMOVE_STIMULUS(state, stimulusId) {
    state.stimulusList = state.stimulusList.filter(s => {
      return s._id !== stimulusId;
    });
  },
  SET_CATEGORY_OPTIONS(state, categoryOptions) {
    state.categoryOptions = categoryOptions;
  }
};

const actions = {
  retrieveVideoCategories({ commit }) {
    return services.CategoryService.retrieveVideoCategories()
      .then(response => {
        const videoCategories = response.map(category => {
          return {
            id: category._id,
            name: category.name
          };
        });

        const SORT_BY = "asc";
        const sortedList = orderBy(
          videoCategories,
          category => category.name.toLowerCase(),
          SORT_BY
        );

        commit("SET_CATEGORY_OPTIONS", sortedList);
      })
      .catch(error => {
        commit("SET_CATEGORY_OPTIONS", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  createProjectAsset({ commit, rootGetters }, stimulus) {
    const projectId = rootGetters["project/getActiveProjectId"];
    const videoInfo = {
      file: stimulus.file,
      name: stimulus.name,
      type: stimulus.type,
      status: stimulus.status,
      category: stimulus.category,
      requiredRespondents: stimulus.requiredRespondents
    };
    return services.ProjectService.createProjectAsset(projectId, videoInfo)
      .then(response => {
        commit("ADD_STIMULUS", {
          ...response,
          file: null,
          contentTypeOther: ""
        });
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  retrieveProjectAssets({ commit, rootGetters }) {
    const projectId = rootGetters["project/getActiveProjectId"];
    return services.ProjectService.retrieveProjectAssets(projectId)
      .then(response => {
        const stimulusList = [];
        response.forEach(stimulusItem => {
          stimulusList.push({
            ...stimulusItem,
            file: null,
            contentTypeOther: ""
          });
        });
        commit("SET_STIMULUS_LIST", stimulusList);
      })
      .catch(error => {
        commit("SET_STIMULUS_LIST", []);
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  updateProjectAsset({ commit, rootGetters }, stimulus) {
    const projectId = rootGetters["project/getActiveProjectId"];
    const assetId = stimulus._id;
    const videoInfo = {
      name: stimulus.name,
      type: stimulus.type,
      status: stimulus.status,
      category: stimulus.category,
      requiredRespondents: stimulus.requiredRespondents
    };
    return services.ProjectService.updateProjectAsset(
      projectId,
      assetId,
      videoInfo
    )
      .then(response => {
        commit("UPDATE_STIMULUS", response);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  },
  deleteProjectAsset({ commit, rootGetters }, assetId) {
    const projectId = rootGetters["project/getActiveProjectId"];
    return services.ProjectService.deleteProjectAsset(projectId, assetId)
      .then(response => {
        commit("REMOVE_STIMULUS", assetId);
      })
      .catch(error => {
        checkRedirectionByStatus(error.status, commit);
        commit(
          "error/SET_ERROR",
          { status: error.status },
          {
            root: true
          }
        );
        return Promise.reject();
      });
  }
};

function checkRedirectionByStatus(status, commit) {
  if (status === AUTHORIZATION_ERROR_STATUS) {
    commit("auth/SET_IS_LOGGED_IN", false, { root: true });
    router.replace({ name: "Login" });
  }
}

export default {
  namespaced: true,
  strict,
  state,
  getters,
  mutations,
  actions
};
